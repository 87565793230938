import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useLocation } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete'; 
import TextField from '@material-ui/core/TextField';
import { API_BASE_URL } from "../../../../_services/constant";
import { Button, Form, FormGroup, Label, Input, FormText, Row, Col, Alert } from "reactstrap";
import {
  postMethod,
  validateMobile,
  singleParam,
  editUserGrid,
} from "../../../../_services/_postMethod";
import Loader from "../../../modules/loader";
import { post } from "axios";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AddCategory = (props) => {
  let queryParams = useQuery();
  const id = queryParams.get("id") == "" ? null : queryParams.get("id");
  const { register, handleSubmit, errors } = useForm();
  const [load, setLoad] = useState(false);
  const [loadInser, setLoadInsert] = useState(false);
  const [loadDelete, setLoadDelete] = useState(false);  
  const [loadDeleteMsg, setLoadDeleteMsg] = useState("Deleted");
  const [imageValidation1, setImageValidation1] = useState(false);
  const [imageValidation2, setImageValidation2] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [formInsertData, setFormInsertData] = useState({
    category_id: "0",
    category_name: "",
    image_path:"",
    banner_path:""
  });
  const [imageObj, setImageObj] = useState({
    image: "",
    banner: ""
  });
  const [success, setSuccess] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false); 

  function createGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) { 
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const insertData = (values) => {
    let file = document.getElementById("categoryImage");
    let files = file.files;
    let formData = new FormData();
    let fileBanner = document.getElementById("categoryBanner");
    let filesBanner = fileBanner.files;
    let formDataBanner = new FormData();

     if(formInsertData.image_path == "" && files.length == 0)
     {
       setImageValidation1(true);
     }
     else if(formInsertData.banner_path == "" && filesBanner.length == 0)
     {
        setImageValidation2(true);
     }
     else{
       
      if (files.length > 0) {
        for (var j = 0; j < files.length; j++) {
          let file_name = "image-" + createGuid();
          
          // Get the file extension
          let extension = files[j].name.substring(files[j].name.lastIndexOf("."));
          
          // Change .jpeg to .jpg
          if (extension.toLowerCase() === ".jpeg") {
            extension = ".jpg";
          }
          
          // Construct the image path with the modified extension
          values.image_path = file_name + extension;
          formInsertData.image_path = file_name + extension;
          
          // Append the file with the new file name
          formData.append("files", files[j], file_name + extension);
        }
      }
      
          else
          {
            values.image_path = formInsertData.image_path;
          }


      if (filesBanner.length > 0) {



        for (var j = 0; j < filesBanner.length; j++) {
          let file_name = "image-" + createGuid();
          
          // Get the file extension
          let extension = filesBanner[j].name.substring(filesBanner[j].name.lastIndexOf("."));
          
          // Change .jpeg to .jpg
          if (extension.toLowerCase() === ".jpeg") {
            extension = ".jpg";
          }
          
          // Construct the image path with the modified extension
          values.banner_path = file_name + extension;
          formInsertData.banner_path = file_name + extension;
          
          // Append the file with the new file name
          formData.append("files", filesBanner[j], file_name + extension);
        }

        }
        else
        {
          values.banner_path = formInsertData.banner_path;
        }
      
       values.category_id = formInsertData.category_id; 
       values.category_name = formInsertData.category_name; 
       setLoadInsert(true);
      postMethod("api/masters/product/category/insert", values).then((dataD) => { 
      var data =dataD.data[0];    
      if (data.valid) {
        if (files.length > 0) {
          uploadImageFunc();
        } 
        else if (filesBanner.length > 0) {
          uploadImageBannerFunc();
        } 
        else {
          setSuccess(true);
          setTimeout(() => {
            props.history.push("/branch/product/category");
          }, 2000);
        }

      }
    });
  }
  };
  

  
  const uploadImageFunc = () => {
    let file = document.getElementById("categoryImage");
    let files = file.files;
    let formData = new FormData();


    if (files.length > 0) {
      for (var j = 0; j < files.length; j++) { 
        formData.append("files", files[j], formInsertData.image_path.split(".")[0]);
      }
    }


    let fileBanner = document.getElementById("categoryBanner");
    let filesBanner = fileBanner.files;
   

    const url = API_BASE_URL + `api/masters/branch/UploadProductCategory`;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "auth-token": localStorage.getItem("jwl_token"),
      },
    };

    post(url, formData, config).then(
      (response) => {
       if (filesBanner.length > 0) {
          uploadImageBannerFunc();
        } 
        else {
          setSuccess(true);
          setTimeout(() => {
            props.history.push("/branch/product/category");
          }, 2000);
        }
        
      },
      (error) => {
        setSuccess(false);
          setLoadInsert(false);
      }
    );
  };

  const uploadImageBannerFunc = () => {

    let fileBanner = document.getElementById("categoryBanner");
    let filesBanner = fileBanner.files;
    let formDataBanner = new FormData();

    if (filesBanner.length > 0) {
      for (var j = 0; j < filesBanner.length; j++) {     
       formDataBanner.append("files", filesBanner[j], formInsertData.banner_path.split(".")[0]);
      }
    }

    const url = API_BASE_URL + `api/masters/branch/UploadProductCategoryBanner`;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "auth-token": localStorage.getItem("jwl_token"),
      },
    };

    post(url, formDataBanner, config).then(
      (response) => {

          setSuccess(true);
          setTimeout(() => {
            props.history.push("/branch/product/category");
          }, 2000);
        
      },
      (error) => {
        setSuccess(true);
          setLoadInsert(false);
      }
    );
  };


  const handleDelete = () => {
    var proceed = window.confirm("Do you Really Wish to Delete this Entry..?!");

    if (proceed) {
        setLoadDelete(true);
      postMethod("api/masters/product/category/delete", {
        category_id: formInsertData.category_id,
      }).then((data) => {       
        if (data.data[0].valid) {
         setSuccessDelete(true);
          setTimeout(() => {
            props.history.push("/branch/product/category");
          }, 2000);
        }
        else{
            setSuccessDelete(true);
              setTimeout(() => {
               setSuccessDelete(false);
            }, 4000);
             setLoadDeleteMsg(data.error)
           setLoadDelete(false);
        }
      });
    }
  };

  const getCategorySearchList =()=>{
       postMethod("api/masters/product/subcategory/search_list_category", {search:formInsertData.category_name}).then((data) => setCategoryList(data.data));
  }

   useEffect(() => {
   getCategorySearchList();
  }, [formInsertData.category_name]);

  useEffect(() => {
    if (id !== null) {
      setLoad(true);
      var temp = window.atob(id);
      postMethod("api/masters/product/category/preview", { category_id: temp }).then((dataD) => {
        var data = dataD.data[0];
        setFormInsertData({ ...formInsertData,
          category_id:data.category_id,
          category_name: data.category_name,
          banner_path: data.banner_path,
          image_path: data.image_path
        });
         setImageObj({ ...imageObj, image: data.image_path,banner:data.banner_path});
        setLoad(false);
      });
    }
     postMethod("api/masters/product/subcategory/search_list_category", {search:''}).then((data) => setCategoryList(data.data));
  }, []);
  return (
    <>
      {load ? <Loader /> : null}
      <Form onSubmit={handleSubmit(insertData)}>
        <input
          type="hidden"
          name="category_id"
          value={id == null ? "0" : formInsertData.category_id}
          ref={register}
        />
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Add Product Category</h3>
            </div>
          </div>
          <div className="card-body">
            <Row>
              <Col sm={4}>
                <FormGroup>
                  <Label>Category Name</Label>                 
                  <Autocomplete
                      id="CategoryName"
                      freeSolo
                      size="small"
                      value={formInsertData.category_name} 
                      options={categoryList.map((option) => option.category_name)}  
                       onChange={(event, value) => {  
                      setFormInsertData({ ...formInsertData, category_name: value });
                      }}   
                       renderInput={(params) => (
                        <TextField {...params}
                          onChange={(e) => setFormInsertData({ ...formInsertData, category_name: e.target.value })}
                          margin="normal" variant="outlined" />
                      )}
                    />
                </FormGroup>
              </Col>
               
              <Col sm={4} className="d-flex">
              <FormGroup>
                  <Label>
                  Image (350X236)   {imageValidation1 ? <label className="text-danger">*Required</label> : null} <br />
                  { (imageObj.image != "" && imageObj.image !=undefined) ? 
                    <img id="categoryImageshow"
                    width="100"
                    src={API_BASE_URL + "BranchCategory/" + imageObj.image}
                  ></img> : null    }
                    <input
                      id="categoryImage"
                      type="file"
                      multiple={false}
                      accept="image/*"
                      name="Choose Image"
                      onChange={(e)=>  {
                          var reader = new FileReader();
                          reader.onload = function (e) {
                            setImageObj({ ...imageObj, image: e.target.result})
                            document.getElementById('categoryImageshow').src=""+e.target.result+"";
                          }
                          reader.readAsDataURL(e.target.files[0]);
                          setImageValidation1(false);
                    }}
                    />
                  </Label>
                  
                
                  </FormGroup>
              </Col>

              <Col sm={4} className="d-flex">
              <FormGroup>
                  <Label>
                  Banner (1110X320)  {imageValidation2 ? <label className="text-danger">*Required</label> : null}<br />
                  { (imageObj.banner != "" && imageObj.banner !=undefined) ? 
                    <img id="categoryBannershow"
                    width="100"
                    src={API_BASE_URL + "BranchBannerCategory/" + imageObj.banner}
                  ></img> : null    }
                    <input
                      id="categoryBanner"
                      type="file"
                      multiple={false}
                      accept="image/*"
                      name="Choose Image"
                      onChange={(e)=>  {
                          var reader = new FileReader();

                          reader.onload = function (e) {
                            setImageObj({ ...imageObj, banner: e.target.result})
                            document.getElementById('categoryBannershow').src=""+e.target.result+"";                          
                          }
                          reader.readAsDataURL(e.target.files[0]);
                          setImageValidation2(false);
                    }}
                    />
                  </Label>
                  </FormGroup>
              </Col>
            </Row>
          
            {success ? <Alert color="success">Saved Successfully</Alert> : null}
            {successDelete ? <Alert color="danger">{loadDeleteMsg}</Alert> : null}
          </div>
          <div className="card-footer text-right">
            <a href="/branch/product/category" className="btn btn-secondary mr-2">
              Cancel
            </a>
           {id && (
              loadDelete ? <button type="button" className="btn btn-danger mr-2">
                Deleting..
              </button> : 
               <button type="button" onClick={handleDelete} className="btn btn-danger mr-2">
                Delete
              </button>
            )}
          
            {loadInser ? 
             <button type="button" className="btn btn-primary ">
              Saving..
            </button> :  <button type="submit" className="btn btn-primary ">
              Save
            </button>}
          </div>
        </div>
      </Form>
    </>
  );
};

export default AddCategory;
