import React, { useEffect, useState } from "react";
import background from "../../../_assets/media/login_back.jpg";
import Logo from "../../../_assets/media/favicon.png";
import { useForm } from "react-hook-form";
import { Alert } from "reactstrap";

import logo from "../../../_assets/media/home/logo.png";
import header1 from "../../../_assets/media/home/header1.jpg";
import header2 from "../../../_assets/media/home/header2.jpg";
import header3 from "../../../_assets/media/home/header3.jpg";
import { postMethod } from "../../../_services/_postMethod";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import NumberFormat from "react-number-format";
import { API_BASE_URL } from "../../../_services/constant";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    //  backgroundColor: '#fff'
  },
  backdropStarting: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#000",
    backgroundColor: "#000",
  },
}));
const CustomerCustomizedWelcome = (props) => {
  const classes = useStyles();
  const [otp, setOtp] = useState(false);
  const [imgObj, setimgObj] = useState({ image1: "", image2: "", image3: "" });
  const { register, handleSubmit, errors } = useForm();
  const [isvalid, setValid] = useState(false);
  const [load, setLoad] = useState(false);
  const [startingLoading, setStartingLoading] = useState(false);
  const [otpVal, setotpVal] = useState(false);
  const [otpLimitCross, setotpLimitCross] = useState(false);

  const [mobileVal, setmobileVal] = useState(false);
  const [mobile, setmobile] = useState("");
  const [linkexpire_or_not, setlinkexpire_ornot] = useState(false);
  const [branchLogo, setBranchLogo] = useState("");
  const [branchid, setBranchid] = useState("");
  const [year, setyear] = useState("");
  const [branchName, setBranchName] = useState("");
  const [branchFacebook, setBranchFacebook] = useState("");
  const [branchInstagram, setBranchInstagram] = useState("");
  const [branchTwitter, setBranchTwitter] = useState("");
  const [branchLinkedin, setBranchLinkedin] = useState("");
  const loginCustomer = (values) => {
    var mobilevr = mobile
      .trim()
      .replace("+91", "")
      .replace(/ /g, "")
      .replace(/_/g, "");
    values.mobile = mobilevr;
    values.customer_id = localStorage.getItem("jwl_customerID");
    if (mobile.length == 10) {
      setLoad(true);

      if (otp) {
        postMethod(`auth/customized/token`, values).then((dataD) => {
          var data = dataD.data[0];
          if (data.access_token) {
            localStorage.setItem("jwl_login", true);
            localStorage.setItem("jwl_token", data.access_token);
            localStorage.setItem("jwl_username", data.full_name);
            localStorage.setItem("jwl_logo", data.logo);
            localStorage.setItem(
              "jwl_customer_vcall_popup",
              data.popup_required
            );
            localStorage.setItem("jwl_brand_name", data.brand_name);
            localStorage.setItem("jwl_banner_path", data.banner_path);
            localStorage.setItem("jwl_customer_id", data.customer_id);
            localStorage.setItem("jwl_customer_branch_id", data.branch_id);
            localStorage.setItem("jwl_customer_vcall", data.vcall_customer);
            localStorage.setItem("jwl_hide_price", data.hide_price);
            localStorage.setItem("cust_mobile", mobile);
            window.location = `/customersession/home`;
          } else {
            setotpVal(!otpVal);
            setValid(true);
          }
          setLoad(false);
        });
      } else {
        values.mobile = mobile
          .trim()
          .replace("+91", "")
          .replace(" ", "")
          .replace(" ", "")
          .replace(" ", "");
        postMethod(`auth/customized/login`, values).then((data) => {
          if (data.data[0].valid === true) {
            setOtp(true);
            setValid(false);
          } else {
            setotpLimitCross(data.data[0]?.otpLimitCross || false);
            setOtp(false);
            setValid(true);
          }
          setLoad(false);
        });
      }
    } else {
      setmobileVal(true);
    }
  };

  useEffect(() => {
    localStorage.clear();
    localStorage.setItem("type", "CustomerSession");
    const query = new URLSearchParams(window.location.search);
    var token = query.get("t");
    setBranchid(token);
    setyear(new Date().getFullYear());
    postMethod("public/Cusimages", { branch_id: token }).then((data) => {
      if (data.data.length > 0) {
        setimgObj(data.data[0]);
      }
    });
    localStorage.setItem("jwl_customerID", "");
    localStorage.setItem("jwl_customerID", token);
    setStartingLoading(true);
    postMethod("public/branch_detailsCustomization", {
      customer_id: token,
    }).then((data) => {
      setlinkexpire_ornot(data.is_link_expire);
      setBranchLogo(data.logo);
      setmobile(data.mobile);
      setBranchName(data.company_name);
      setBranchFacebook(data.facebook);
      setBranchInstagram(data.instagram);
      setBranchTwitter(data.twitter);

      localStorage.setItem("jwl_facebook", data.facebook);
      localStorage.setItem("jwl_branch_mobile", data.branch_mobile);
      localStorage.setItem("jwl_instagram", data.instagram);
      localStorage.setItem("jwl_twitter", data.twitter);
      setStartingLoading(false);
    });
  }, []);

  return (
    <React.Fragment>
      {linkexpire_or_not == true && (
        <h3 style={{ textAlign: "center" }}>Link Expire</h3>
      )}

      {linkexpire_or_not == false && (
        <React.Fragment>
          <Backdrop className={classes.backdrop} open={load}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="customcontainer tag_wrapper">
            <div className="row toppad3">
              <div className="col-sm-8 logo_session">
                {branchLogo ? (
                  <img
                    src={API_BASE_URL + "BranchLogo/" + branchLogo}
                    className="logo"
                  />
                ) : (
                  <Skeleton variant="rectangular" width={210} height={118} />
                )}
              </div>
              <div className="col-sm-4"></div>
            </div>
            <div className="clr"></div>
            <div className="row">
              <div className="container-fluid bg-white p-4 gnrl_wrap">
                <div className="row">
                  <div className="col-md-4">
                    <div className=" p-0 pr-md-3">
                      <img
                        src={API_BASE_URL + "images/" + imgObj.image1}
                        className="img-fluid mainIMage w-100"
                      />
                    </div>
                  </div>
                  <div className="col-md-1"></div>
                  <div className="col-md-7">
                    <div className=" mtoppad3">
                      <div className="tophead">
                        <h4 className="d-none d-lg-block d-print-block">
                          UNIQUE ACCESSORIES
                        </h4>
                        <h1 className="d-none d-lg-block d-print-block">
                          Your Jewelry Collection
                          <br />
                          Forever
                        </h1>
                        <h4>Crafting Exquisite Jewellery for you..</h4>
                        <form onSubmit={handleSubmit(loginCustomer)}>
                          <div className={"group subs " + (!otp ? "" : "hide")}>
                            <NumberFormat
                              getInputRef={register}
                              onValueChange={(e) => {
                                setmobileVal(false);
                              }}
                              name="mobile"
                              format="+91 ### ### ####"
                              allowEmptyFormatting
                              mask="_"
                            />
                            <span className="highlight"></span>
                            {mobileVal ? (
                              <p style={{ color: "red" }}>
                                Incorrect mobile no.
                              </p>
                            ) : null}
                            {otpLimitCross ? (
                              <p style={{ color: "red" }}>OTP Limit Crossed!</p>
                            ) : null}
                            {linkexpire_or_not ? (
                              <p style={{ color: "red" }}>Link Expired</p>
                            ) : null}
                            <span className="bar"></span>
                          </div>

                          <div className={"group subs " + (!otp ? "hide" : "")}>
                            <input
                              className="form-control form-control-solid h-auto py-3 px-6"
                              ref={register}
                              type="number"
                              placeholder="Enter 6-digit OTP"
                              name="otp"
                              autoComplete="off"
                              style={{ fontWeight: "600" }}
                              onClick={() => {
                                setotpVal(false);
                              }}
                            />
                            {otpVal ? (
                              <p style={{ color: "red" }}>Incorrect OTP.</p>
                            ) : null}
                          </div>
                          <div className="sbmt">
                            <input
                              type="submit"
                              value="Go"
                              className="sbmtbtn"
                            />
                          </div>
                        </form>
                      </div>
                      <div className="tophead d-none d-lg-block d-print-block">
                        <div className="row">
                          <div
                            className="col-md-4 d-flex align-items-center"
                            style={{ gap: "15px" }}
                          >
                            <img
                              src={API_BASE_URL + "images/" + imgObj?.image2}
                              className="img-fluid"
                            />
                            <img
                              src={API_BASE_URL + "images/" + imgObj?.image3}
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {window.outerWidth > 600 ? (
            <footer className="tag_footer">
              <div className="container mcntr">
                <div className="row">
                  <div className="col-sm-10">
                    © {year} {branchName} All Rights Reserved
                  </div>
                  <div className="col-sm-2">
                    <div className="text-center center-block">
                      {branchFacebook ? (
                        <a href={branchFacebook}>
                          <i
                            id="social-fb"
                            className="fa fa-facebook fa-2x social"
                          ></i>
                        </a>
                      ) : null}
                      {branchInstagram ? (
                        <a href={branchInstagram}>
                          <i
                            id="social-gp"
                            className="fa fa-instagram fa-2x social"
                          ></i>
                        </a>
                      ) : null}
                      {branchLinkedin ? (
                        <a href={branchLinkedin}>
                          <i
                            id="social-em"
                            className="fa fa-linkedin fa-2x social"
                          ></i>
                        </a>
                      ) : null}
                      {branchTwitter ? (
                        <a href={branchTwitter}>
                          <i
                            id="social-tw"
                            className="fa fa-twitter fa-2x social"
                          ></i>
                        </a>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          ) : null}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default CustomerCustomizedWelcome;
